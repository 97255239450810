import { type } from '@ngrx/signals';
import { entityConfig } from '@ngrx/signals/entities';

import { Status } from '../../../../enums/status.enum';
import { Angle, DemoModeGroup, Demonstration } from '../demo-mode.interface';

export const demoModeGroupConfig = entityConfig({
  entity: type<DemoModeGroup>(),
  collection: 'groups',
  selectId: (group) => group.id,
});

export const demoModeDemonstrationConfig = entityConfig({
  entity: type<Demonstration>(),
  collection: 'demonstration',
  selectId: (demonstration) => demonstration.id,
});

export const demoModeAngleConfig = entityConfig({
  entity: type<Angle>(),
  collection: 'angles',
  selectId: (slide) => slide.id,
});

export interface DemoModeState {
  groupStatus: Status;
  angleStatus: Status;
  demonstrationStatus: Status;
  error: string;
  isSelectGroupModalOpen: boolean;
}
