import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Injectable, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { addEntity, removeEntity, setAllEntities, setEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { EMPTY, Observable, map, mergeMap, pipe, switchMap, tap } from 'rxjs';

import { BuildingCard } from '../../3dmodels/models3d.interface';
import { Status } from '../../../../enums/status.enum';
import { ToolbarService } from '../../toolbar.service';
import { Angle, AngleCreate, Demonstration, DemonstrationCreate, GetGroupsParam, GetGroupsResponse } from '../demo-mode.interface';
import { rootGroupId } from '../demo-mode.mock';
import { DemoModeService } from '../demo-mode.service';
import { DemoModeState, demoModeAngleConfig, demoModeDemonstrationConfig, demoModeGroupConfig } from './demo-mode.store.type';

/**
 * Сервис хранилища слоев.
 * @class
 */
@Injectable({ providedIn: 'root' })
export class DemoModeStoreService extends signalStore(
  { protectedState: false },
  withDevtools('demo-mode'),
  withState<DemoModeState>({
    groupStatus: Status.UNINITIALIZED,
    demonstrationStatus: Status.UNINITIALIZED,
    angleStatus: Status.UNINITIALIZED,
    error: '',
    isSelectGroupModalOpen: false,
  }),
  withEntities(demoModeGroupConfig),
  withEntities(demoModeDemonstrationConfig),
  withEntities(demoModeAngleConfig),
) {
  /**
   * Функция, которая вычисляет, является ли статус равен UNINITIALIZED.
   * @returns {boolean} Возвращает true, если статус равен UNINITIALIZED, в противном случае возвращает false.
   */
  isGroupUninitialized = computed(() => this.groupStatus() === Status.UNINITIALIZED);

  /**
   * Реактивное вычисляемое свойство, определяющее, полностью ли загружены данные группы.
   * Возвращает логическое значение, указывающее, находится ли `groupStatus` в состоянии `LOADED`.
   * Обычно используется для управления элементами интерфейса и рабочими процессами,
   * зависящими от статуса загрузки данных группы.
   *
   * @type {boolean}
   */
  isGroupLoaded = computed(() => this.groupStatus() === Status.LOADED);

  /**
   * Вычисляемое свойство, определяющее, находится ли текущий статус угла в процессе сохранения.
   *
   * @type {boolean}
   * - Возвращает `true`, если `angleStatus` равно `Status.SAVING`.
   * - Возвращает `false` в противном случае.
   */
  isAngleSaving = computed(() => this.angleStatus() === Status.SAVING);

  /**
   * Сервис, используемый для управления состоянием демо-режима приложения.
   *
   * Данный сервис предоставляет функциональность для включения, отключения и проверки статуса
   * демо-режима в приложении. Демо-режим обычно используется для демонстрации возможностей или запуска
   * приложения в образцовом состоянии для обучения или презентации.
   *
   * DemoModeService может предоставлять различные методы и свойства для управления этими аспектами,
   * и этот сервис внедряется везде, где требуется функциональность демо-режима.
   *
   * @type {DemoModeService}
   */
  #demoModeService = inject(DemoModeService);

  /**
   * Переменная `router` является экземпляром класса Router, предоставляемого при помощи механизма внедрения зависимостей.
   *
   * В основном используется для навигации между различными маршрутами в приложении, управления логикой навигации,
   * выполнения перенаправлений и управления событиями изменения маршрутов. Используя внедрение зависимостей,
   * экземпляр `Router` может быть легко внедрён и использован везде, где необходимы функции маршрутизации.
   *
   * Тип: Router
   *
   * Контекст использования:
   * Может использоваться в компонентах, сервисах или в любом месте, где требуется навигация по маршрутам.
   *
   * Типичные случаи использования:
   * - Программная навигация к различным маршрутам
   * - Перенаправление пользователей на основе определенных условий
   * - Прослушивание изменений маршрутов и выполнение соответствующей логики
   */
  #router = inject(Router);

  /**
   * Управляет функционалом панели инструментов.
   */
  readonly #toolbarService = inject(ToolbarService);

  /**
   * Извлекает и обрабатывает группы на основе заданных условий.
   *
   * Эта функция использует RxJS pipeline для фильтрации, обновления состояния
   * и переключения на новый observable, который загружает группы.
   *
   * Pipeline состоит из следующих этапов:
   * - Фильтр, чтобы продолжать только когда объект не инициализирован.
   * - Tap для обновления статуса состояния на LOADING.
   * - SwitchMap для преобразования потока и загрузки групп.
   */
  readonly getGroups = rxMethod<GetGroupsParam | void>(
    pipe(
      tap(() => patchState(this, { groupStatus: Status.LOADING })),
      switchMap((params) => this.loadGroups(params ?? undefined)),
    ),
  );

  /**
   * Получает демонстрацию по ее идентификатору.
   *
   * Эта переменная извлекает информацию о демонстрации по ее идентификатору. Поведение двоякое:
   *
   * 1. Если демонстрации уже загружены, она берет необходимую информацию из хранилища и добавляет соответствующие ракурсы.
   * 2. Если демонстрации еще не загружены (например, при прямом переходе по ссылке), она вызывает метод `getDemonstrationById`,
   *    добавляет демонстрацию в хранилище вместе с соответствующими ракурсами.
   *
   * Идентификатор демонстрации передается как аргумент, и в зависимости от состояния инициализации, решается, загружать данные из хранилища
   * или извлекать их с удаленного сервера.
   *
   * @param {string} demonstrationId - Уникальный идентификатор демонстрации.
   * @returns {Observable} Observable, который эмитирует детали демонстрации и ракурсы.
   */
  readonly getDemonstrationById = rxMethod<Demonstration['id']>(
    pipe(
      /*
       * если демо загружены - беру из стора и добавляю ракурсы
       * если демо еще не загружены (открыто по прямой ссылке) - вызываю метод getDemonstrationById, добавляю в стор демонстрацию и
       * ракурсы
       */
      switchMap((demonstrationId) =>
        this.isGroupUninitialized() ? this.loadDemonstrationById(demonstrationId) : this.getAnglesFromCurrentDemonstration(demonstrationId),
      ),
    ),
  );

  /**
   * Реактивный метод, который создаёт демонстрацию. Процесс начинается с обновления состояния на 'LOADING'.
   * Затем добавляется демонстрация и после успешного создания переходит к новой демонстрации.
   *
   * @name createDemonstration
   * @type {Method<DemonstrationCreate>}
   */
  readonly createDemonstration = rxMethod<DemonstrationCreate>(
    pipe(
      tap(() => patchState(this, { demonstrationStatus: Status.LOADING })),
      switchMap((data) => this.addDemonstration(data)),
      tap((demonstration) => {
        this.#toolbarService.closeSubpanelsEvent$.next(true);
        this.#router.navigate([{ outlets: { primary: null, 'demo-mode': ['demo-mode', demonstration.id] } }]);
      }),
    ),
  );

  /**
   * Observable-метод для удаления демонстрационной сущности по её id.
   *
   * Метод выполняет несколько операций последовательно:
   * 1. Обновляет состояние, удаляя сущность с указанным id.
   * 2. Инициирует запрос на удаление демонстрации через demoModeService.
   *
   * @memberof demonstration
   * @method deleteDemonstration
   * @param {Demonstration['id']} id - ID демонстрационной сущности для удаления.
   * @returns {Observable<any>} Observable, который завершает операцию удаления.
   */
  readonly deleteDemonstration = rxMethod<Demonstration['id']>(
    pipe(
      tap((id) => patchState(this, removeEntity(id, demoModeDemonstrationConfig))),
      mergeMap((id) => this.#demoModeService.deleteDemonstrationById(id)),
    ),
  );

  /**
   * Переменная, представляющая метод для создания конфигурации угла.
   *
   * Данный метод включает в себя ряд операций, включая:
   * - Установку статуса угла в "SAVING".
   * - Создание угла с предоставленными данными, исключая Blob.
   * - Возврат созданного угла с добавленным Blob.
   * - Загрузку миниатюрного представления созданного угла.
   *
   * Входные данные для создания угла включают следующие свойства объекта Angle:
   * - camera_angle
   * - camera_position
   * - demonstration_id
   * - light
   * - name
   *
   * Кроме того, входные данные должны включать объект Blob.
   */
  readonly createAngle = rxMethod<AngleCreate>(
    pipe(
      tap(() => patchState(this, { angleStatus: Status.SAVING })),
      mergeMap((angleData) => this.addAngle(angleData)),
      mergeMap((createdAngleWithBlob) => this.loadMiniature(createdAngleWithBlob)),
    ),
  );

  /**
   * Переменная `deleteAngle` является функцией, которая выполняет удаление сущности, идентифицируемой по ее ID.
   * Она использует `rxMethod` для обработки процесса удаления в рамках реактивного программирования,
   * в частности, с использованием библиотеки ReactiveX (RxJS).
   *
   * Этот метод принимает ID типа `string` в качестве входного параметра, обрабатывает его через конвейер (`pipe` функция),
   * где оператор `tap` используется для применения побочных эффектов, таких как обновление состояния
   * путем удаления сущности, соответствующей предоставленному ID и конфигурации, определенной `demoModeAngleConfig`.
   *
   * Функция `patchState` используется для упрощения изменения состояния путем удаления сущности,
   * обеспечивая согласованность состояния приложения с операцией удаления.
   *
   * @type {function(string): void}
   */
  readonly deleteAngle = rxMethod<Angle['id']>(
    pipe(
      tap((id) => patchState(this, removeEntity(id, demoModeAngleConfig))),
      mergeMap((angleId) => this.#demoModeService.deleteAngle(angleId)),
    ),
  );

  /**
   * `updateAngle` — это метод на основе Observable, используемый для обновления угла объекта.
   *
   * Этот метод использует подход реактивного программирования для изменения состояния объекта,
   * идентифицируемого по `id`, с обновленными изменениями, такими как `name`. Обновление применяется
   * с помощью функции `updateEntity` с предоставленной конфигурацией `demoModeAngleConfig`.
   *
   * Метод структурирован с использованием оператора `pipe` и включает оператор `tap` для
   * выполнения побочного эффекта изменения состояния.
   */
  readonly updateAngle = rxMethod<Angle>(
    pipe(
      tap((angle) =>
        patchState(
          this,
          updateEntity(
            {
              id: angle.id,
              changes: { name: angle.name },
            },
            demoModeAngleConfig,
          ),
        ),
      ),
      mergeMap((angle) => this.#demoModeService.updateAngle(angle)),
    ),
  );

  /**
   * Сбрасывает углы в состоянии приложения.
   *
   * Эта переменная представляет собой наблюдаемое действие, которое, когда
   * активировано, сбрасывает углы, обновляя состояние приложения пустым массивом
   * и применяя конфигурацию углов для демонстрационного режима. Оно использует
   * реактивный метод для стриминга изменений и гарантирует, что все сущности
   * типа Angle удаляются из текущего состояния, эффективно сбрасывая любые
   * данные, связанные с углами.
   *
   * Использование rxMethod гарантирует, что эта операция выполняется
   * реактивно, обеспечивая обновление состояния в ответ на определенные
   * триггеры или действия в контексте приложения.
   */
  readonly resetAngles = rxMethod<void>(pipe(tap(() => patchState(this, setAllEntities([] as Angle[], demoModeAngleConfig)))));

  /**
   * Устанавливает состояние для открытия или закрытия модального окна выбора группы.
   *
   * @param {boolean} value - Новое состояние модального окна выбора группы.
   *                          Если true, модальное окно будет открыто; если false, оно будет закрыто.
   *
   * @return {void}
   */
  setIsSelectGroupModalOpen(value: boolean): void {
    patchState(this, { isSelectGroupModalOpen: value });
  }

  /**
   * Определяет, находится ли указанное здание в новом демонстрационном режиме.
   * @param {string} buildingId - Идентификатор проверяемого здания.
   * @return {boolean} - Возвращает true, если здание находится в новом демонстрационном режиме, иначе - false.
   */
  isNewDemoMode(buildingId: BuildingCard['buildingId']): boolean {
    return !this.demonstrationEntities().some((demonstration) => demonstration.model_guid === buildingId);
  }

  /**
   * Проверяет, занято ли указанное имя в списке демонстраций.
   *
   * @param {string} name - Имя, которое нужно проверить на наличие.
   * @return {boolean} Возвращает true, если имя уже занято, иначе false.
   */
  isValueExists(name: string): boolean {
    return this.demonstrationEntities()
      .map((demo) => demo.name)
      .includes(name);
  }

  /**
   * Открывает демонстрационный режим для указанного здания.
   *
   * @param {BuildingCard['buildingId']} buildingId - Уникальный идентификатор здания.
   * @param {string} buildingName - Название здания.
   * @return {void} Этот метод не возвращает значение.
   */
  openDemoMode({ buildingName, buildingId }: { buildingId: BuildingCard['buildingId']; buildingName: BuildingCard['name'] }): void {
    if (this.isNewDemoMode(buildingId)) {
      this.createDemonstration({ name: buildingName, model_guid: buildingId, group_id: rootGroupId });
    } else {
      //find and use the old one
      const demonstration = this.demonstrationEntities().find((demonstration) => demonstration.model_guid === buildingId);

      if (demonstration) {
        this.#router.navigate([{ outlets: { primary: null, 'demo-mode': ['demo-mode', demonstration.id] } }]);
      }
    }
  }

  /**
   * Загружает группы и обновляет состояние, устанавливая сущности и статус.
   *
   * @return {Observable<DemoModeGroup[]>} - Наблюдаемый объект, испускающий список групп в демо-режиме.
   */
  private loadGroups(params?: GetGroupsParam): Observable<GetGroupsResponse> {
    return this.#demoModeService.getGroups(params).pipe(
      tapResponse({
        next: (groups) => {
          const demonstrations = groups.results.flatMap((group) => group.demonstrations);
          patchState(this, setAllEntities(groups.results, demoModeGroupConfig));
          patchState(this, setAllEntities(demonstrations, demoModeDemonstrationConfig));
        },
        error: console.error,
        finalize: () => patchState(this, { groupStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Загружает демонстрацию по ее уникальному идентификатору.
   *
   * @param {string} id - Уникальный идентификатор демонстрации для загрузки.
   * @return {Observable<Demonstration>} - Наблюдаемый объект, который эмитирует загруженную демонстрацию.
   */
  private loadDemonstrationById(id: Demonstration['id']): Observable<Demonstration> {
    return this.#demoModeService.getDemonstrationById(id).pipe(
      tapResponse({
        next: (demonstration) => {
          patchState(this, setEntity(demonstration, demoModeDemonstrationConfig));
          patchState(this, setAllEntities(demonstration.angles, demoModeAngleConfig));
        },
        error: console.error,
        finalize: () => patchState(this, { groupStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Загружает миниатюрное представление угла с сопутствующими данными.
   *
   * @param {Angle & { blob: Blob }} angleWithBlob - Объект, содержащий данные угла и Blob файла.
   * @return {Observable<Angle>} Observable, испускающий обновленный угол с загруженной миниатюрой.
   */
  private loadMiniature(angleWithBlob: Angle & { blob: Blob }): Observable<Angle> {
    const formData = new FormData();
    formData.append('file', angleWithBlob.blob);
    formData.append('angle_id', angleWithBlob.id);
    return this.#demoModeService.loadMiniature(formData).pipe(
      map((miniature) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { blob, ...dataCreate } = angleWithBlob;
        return { ...dataCreate, miniature };
      }),
      tapResponse({
        next: (angle) => patchState(this, addEntity(angle, demoModeAngleConfig)),
        error: console.error,
        finalize: () => patchState(this, { angleStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Добавляет новый угол с предоставленными данными.
   *
   * @param {AngleCreate} angleData - Данные, используемые для создания нового угла, включая Blob.
   * @return {Observable<Angle & { blob: Blob }>} - Observable, содержащий созданный угол и связанный с ним Blob.
   */
  private addAngle(angleData: AngleCreate): Observable<Angle & { blob: Blob }> {
    const { blob, ...dataCreate } = angleData;
    return this.#demoModeService.createAngle(dataCreate).pipe(
      map((createdAngle) => ({ ...createdAngle, blob })),
      tapResponse({
        next: () => {},
        error: (err) => {
          console.error(err);
          patchState(this, { angleStatus: Status.FAILED });
        },
      }),
    );
  }

  /**
   * Добавляет новую демонстрацию, используя предоставленные данные, и обновляет состояние.
   *
   * @param {DemonstrationCreate} demonstrationData - Данные, используемые для создания демонстрации.
   * @return {Observable<Demonstration>} - Observable, который передаёт созданную демонстрацию.
   */
  private addDemonstration(demonstrationData: DemonstrationCreate): Observable<Demonstration> {
    return this.#demoModeService.createDemonstration(demonstrationData).pipe(
      tapResponse({
        next: (demonstration) => {
          patchState(this, addEntity(demonstration, demoModeDemonstrationConfig));
          patchState(this, setAllEntities([] as Angle[], demoModeAngleConfig));
        },
        error: console.error,
        finalize: () => patchState(this, { demonstrationStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Получает углы из текущей демонстрации, указанной данным идентификатором.
   *
   * @param {string} demonstrationId - Уникальный идентификатор демонстрации.
   * @return {Observable<never>} Объект Observable, который не передает элементы наблюдателю и сразу отправляет уведомление о завершении.
   */
  private getAnglesFromCurrentDemonstration(demonstrationId: Demonstration['id']): Observable<never> {
    const angles = this.demonstrationEntityMap()[demonstrationId].angles;
    patchState(this, setAllEntities(angles, demoModeAngleConfig));
    return EMPTY;
  }

  /**
   * Добавляет карточку здания в указанную группу, обновляя список идентификаторов зданий группы.
   *
   * @param {BuildingCard['buildingId']} buildingId - Идентификатор карточки здания, добавляемой в группу.
   * @param {string} groupId - Идентификатор группы, в которую будет добавлена карточка здания.
   * @return {void} Нет возвращаемого значения.
   */
  // private addObjectCard2Group(buildingId: BuildingCard['buildingId'], groupId: string): void {
  //   patchState(
  //     this,
  //     updateEntity(
  //       { id: rootGroupId, changes: { buildingIds: [...this.groupsEntityMap()[groupId].buildingIds, buildingId] } },
  //       demoModeGroupConfig,
  //     ),
  //   );
  // }
}
