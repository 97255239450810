import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

import { BuildingCard } from '../3dmodels/models3d.interface';
import { customException } from '../../../utils/capture-exception.util';
import {
  Angle,
  AngleCreate,
  ChangePositionRequest,
  ChangePositionResponse,
  DemoModeGroup,
  Demonstration,
  DemonstrationCreate,
  DemonstrationUpdate,
  GetGroupsParam,
  GetGroupsResponse,
} from './demo-mode.interface';
import { rootGroupId } from './demo-mode.mock';

/**
 * Сервис, предоставляющий функциональные возможности и данные, специфичные для демо-режима.
 *
 * Этот сервис включает методы для получения групп в демо-режиме и демонстраций.
 */
@Injectable({ providedIn: 'root' })
export class DemoModeService {
  /**
   * Внедряет зависимость HttpClient в переменную httpClient.
   *
   * @param {HttpClient} inject - Объект, который будет внедрен в переменную httpClient
   */
  #httpClient = inject(HttpClient);

  /**
   * Получает группы с опциональной пагинацией и фильтрацией по стенду.
   *
   * @param {Object} [options] - Параметры для получения групп.
   * @param {number} [options.page] - Номер страницы для получения.
   * @param {number} [options.pageSize] - Количество элементов на странице.
   * @param {string} [options.stand] - Стенд для фильтрации групп.
   * @return {Observable<GetGroupsResponse>} Наблюдаемый объект, содержащий ответ с группами.
   */
  getGroups(
    { ordering = '-created_at', page, pageSize, stand, search }: GetGroupsParam = { ordering: '-created_at' },
  ): Observable<GetGroupsResponse> {
    let params = new HttpParams();

    if (page) {
      params = params.append('page', page);
    }

    if (pageSize) {
      params = params.append('page_size', pageSize);
    }

    if (stand) {
      params = params.append('stand', stand);
    }

    if (search) {
      params = params.append('search', search);
    }

    params = params.append('ordering', ordering);

    return this.#httpClient.get<GetGroupsResponse>('/meta_api/presentation/group/all/', { params }).pipe(
      catchError((err) => {
        customException({ msg: 'getGroups', err });
        return of({
          count: 0,
          next: null,
          previous: null,
          results: [],
        });
      }),
    );
  }

  /**
   * Создает новую группу с указанным именем.
   *
   * @param {string} name - Имя создаваемой группы.
   * @return {Observable<DemoModeGroup>} Observable, который эмитирует вновь созданную группу.
   */
  createGroup(name: string): Observable<DemoModeGroup> {
    return this.#httpClient.post<DemoModeGroup>(`meta_api/presentation/group/`, { name }).pipe(
      catchError((err) => {
        customException({ msg: 'createGroup', err });
        throw err;
      }),
    );
  }

  /**
   * Получает DemoModeGroup по его идентификатору.
   *
   * @param {string} [id=rootGroupId] - Идентификатор группы для получения. По умолчанию используется rootGroupId, если не указан.
   * @return {Observable<DemoModeGroup>} - Объект типа Observable, содержащий запрашиваемую DemoModeGroup.
   */
  getGroupById(id: DemoModeGroup['id'] = rootGroupId): Observable<DemoModeGroup> {
    return this.#httpClient.get<DemoModeGroup>(`meta_api/presentation/group/${id}/`).pipe(
      catchError((err) => {
        customException({ msg: 'getGroupById', err });
        throw err;
      }),
    );
  }

  /**
   * Создает новый угол с использованием предоставленных данных угла.
   *
   * @param {Object} angle - Объект угла, содержащий необходимые свойства.
   * @param {string} angle.camera_angle - Данные угла камеры.
   * @param {string} angle.camera_position - Данные позиции камеры.
   * @param {string} angle.demonstration_id - Идентификатор демонстрации.
   * @param {string} angle.light - Информация о освещении.
   * @param {string} angle.name - Имя угла.
   * @return {Observable<Angle>} Объект Observable созданного угла.
   */
  createAngle(angle: Omit<AngleCreate, 'blob'>): Observable<Angle> {
    return this.#httpClient.post<Angle>('/meta_api/presentation/angle/', angle).pipe(
      catchError((err) => {
        customException({ msg: 'createAngle', err });
        throw err;
      }),
    );
  }

  /**
   * Обновляет информацию о ракурсе на сервере.
   *
   * @param {Angle} angle - Объект ракурса, содержащий обновленную информацию.
   * @return {Observable<boolean>} Observable, который выдаёт true, если обновление успешно, иначе выбрасывает ошибку.
   */
  updateAngle(angle: Angle): Observable<boolean> {
    return this.#httpClient.patch<void>(`/meta_api/presentation/angle/${angle.id}/update/`, { name: angle.name }).pipe(
      map(() => true),
      catchError((err) => {
        customException({ msg: 'updateAngle', err });
        throw err;
      }),
    );
  }

  /**
   * Удаляет угол, указанный по его angleId.
   *
   * @param {Pick<Angle, 'id'>} angleId - Уникальный идентификатор угла для удаления.
   * @return {Observable<boolean>} Наблюдаемый объект, который передаёт значение типа boolean, указывающее на успешность удаления.
   */
  deleteAngle(angleId: string): Observable<boolean> {
    return this.#httpClient.delete<void>(`/meta_api/presentation/angle/${angleId}/delete/`).pipe(
      map(() => true),
      catchError((err) => {
        customException({ msg: 'deleteAngle', err });
        throw err;
      }),
    );
  }

  /**
   * Загружает миниатюру, отправляя предоставленные данные формы на сервер.
   *
   * @param {FormData} formData - Объект FormData, содержащий данные для отправки.
   * @return {Observable<Angle['miniature']>} Observable, испускающий загруженную миниатюру.
   */
  loadMiniature(formData: FormData): Observable<Angle['miniature']> {
    return this.#httpClient.post<Angle['miniature']>('/meta_api/presentation/miniature/', formData).pipe(
      catchError((err) => {
        customException({ msg: 'loadMiniature', err });
        throw err;
      }),
    );
  }

  /**
   * Создает новую демонстрацию, отправляя необходимые данные на сервер.
   *
   * @param {Object} demonstration - Данные демонстрации, необходимые для создания новой демонстрации.
   * @param {number} demonstration.group_id - ID группы, связанной с демонстрацией.
   * @param {string} demonstration.model_guid - GUID модели, связанной с демонстрацией.
   * @param {string} demonstration.name - Название демонстрации.
   * @return {Observable<Demonstration>} Объект типа Observable, содержащий созданную демонстрацию.
   */
  createDemonstration(demonstration: DemonstrationCreate): Observable<Demonstration> {
    return this.#httpClient.post<Demonstration>('/meta_api/presentation/demonstration/', demonstration).pipe(
      catchError((err) => {
        customException({ msg: 'createDemonstration', err });
        throw err;
      }),
    );
  }

  /**
   * Получает демонстрацию по её ID.
   *
   * @param {string} id - Уникальный идентификатор демонстрации.
   * @return {Observable<Demonstration>} Наблюдаемый объект, содержащий данные демонстрации.
   */
  getDemonstrationById(id: Demonstration['id']): Observable<Demonstration> {
    return this.#httpClient.get<Demonstration>(`/meta_api/presentation/demonstration/${id}/`).pipe(
      catchError((err) => {
        customException({ msg: 'getDemonstrationById', err });
        throw err;
      }),
    );
  }

  /**
   * Удаляет демонстрацию по её идентификатору.
   *
   * @param {string} id - Уникальный идентификатор демонстрации, которую нужно удалить.
   * @return {Observable<boolean>} Observable, который излучает `true`, если удаление прошло успешно.
   */
  deleteDemonstrationById(id: Demonstration['id']): Observable<boolean> {
    return this.#httpClient.delete<void>(`/meta_api/presentation/demonstration/${id}/delete/`).pipe(
      map(() => true),
      catchError((err) => {
        customException({ msg: 'deleteDemonstrationById', err });
        throw err;
      }),
    );
  }

  /**
   * Обновляет демонстрацию по её ID с предоставленной информацией.
   *
   * @param {Demonstration} updatedDemonstration - Объект демонстрации, содержащий обновлённые данные.
   * @return {Observable<boolean>} - Observable, который эмиттит true, если обновление успешно, иначе ошибка.
   */
  updateDemonstrationById(updatedDemonstration: Demonstration): Observable<boolean> {
    return this.#httpClient
      .patch<void>(`/meta_api/presentation/demonstration/${updatedDemonstration.id}/update/`, {
        name: updatedDemonstration.name,
        group_id: updatedDemonstration.group_id,
      })
      .pipe(
        map(() => true),
        catchError((err) => {
          customException({ msg: 'updateDemonstrationById', err });
          throw err;
        }),
      );
  }

  /**
   * Обновляет детали демонстрации на основе предоставленной информации.
   *
   * @param {Object} demonstration - Объект, содержащий детали демонстрации.
   * @param {string} demonstration.group_id - Идентификатор группы, связанной с демонстрацией.
   * @param {string} demonstration.id - Уникальный идентификатор демонстрации.
   * @param {string} demonstration.name - Новое имя демонстрации.
   *
   * @return {Observable<void>} Observable, который завершится, когда операция обновления будет закончена.
   */
  updateDemonstration(demonstration: DemonstrationUpdate): Observable<void> {
    return this.#httpClient
      .patch<void>(`/meta_api/presentation/demonstration/${demonstration.id}/update/`, {
        name: demonstration.name,
        group_id: demonstration.group_id,
      })
      .pipe(
        catchError((err) => {
          customException({ msg: 'updateDemonstration', err });
          throw err;
        }),
      );
  }

  /**
   * Закрывает модальное окно группы выбора, устанавливая его состояние в false.
   *
   * @return {void}
   */
  getDemonstrationByBuildingGuid(guid: BuildingCard['buildingId']): Observable<Demonstration> {
    return this.#httpClient.get<Demonstration>(`/meta_api/presentation/demonstration/model/${guid}/`).pipe(
      catchError((err) => {
        customException({ msg: 'getDemonstrationByBuildingGuid', err });
        throw err;
      }),
    );
  }

  /**
   * Изменяет позицию объекта на основе предоставленных параметров.
   *
   * @param {ChangePositionRequest & { objId: string }} changePositionParam - Параметры для изменения позиции, включая ID объекта.
   * @return {Observable<ChangePositionResponse>} Observable, который эмиттирует ответ операции изменения позиции.
   */
  changeObjectPosition(changePositionParam: ChangePositionRequest & { objId: string }): Observable<ChangePositionResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { objId, ...rest } = changePositionParam;

    return this.#httpClient.post<ChangePositionResponse>(`/meta_api/position/${changePositionParam.objId}`, rest).pipe(
      catchError((err) => {
        customException({ msg: 'changeObjectPosition', err });
        throw err;
      }),
    );
  }
}
